<template>
  <b-card v-if="generalReportData" no-body class="card-statistics">
    <b-card-header>
      <b-card-title>{{'Order'}}</b-card-title>
      <b-card-text class="font-small-2 mr-25 mb-0">
      </b-card-text>
    </b-card-header>
    <b-card-body class="statistics-body">
      <b-row>
        <b-col xl="4" sm="4" class="mb-2 mb-xl-0">
          <b-media no-body>
            <b-media-aside class="mr-2">
              <b-avatar size="48" variant="light-primary">
                <feather-icon size="24" icon="TrendingUpIcon" />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                {{ generalReportData.orderReport.orderCount }}
              </h4>
              <b-card-text class="font-small-3 mb-0">
                {{ 'Orders' }}
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
        <b-col xl="4" sm="4" class="mb-2 mb-xl-0">
          <b-media no-body>
            <b-media-aside class="mr-2">
              <b-avatar size="48" variant="light-danger">
                <feather-icon size="24" icon="CodeIcon" />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                {{ generalReportData.orderReport.coordinateOrderCount }}
              </h4>
              <b-card-text class="font-small-3 mb-0">
                {{ 'Coordinate' }}
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
        <b-col xl="4" sm="4" class="mb-2 mb-sm-0">
          <b-media no-body>
            <b-media-aside class="mr-2">
              <b-avatar size="48" variant="light-success">
                <feather-icon size="24" icon="DatabaseIcon" />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                {{ generalReportData.orderReport.rawDataOrderCount }}
              </h4>
              <b-card-text class="font-small-3 mb-0">
                {{ 'Raw Data' }}
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
export default {
  computed: {
    generalReportData() {
      return this.$store.getters['homeReportManagement/getGeneralHomeReport'];
    },
  },
};
</script>
