<template>
  <div v-if="generalReportData">
    <b-card no-body class="payment-rep-card">
      <b-card-body class="d-flex justify-content-between align-items-center">
        <div class="truncate">
          <h2 class="mb-25 font-weight-bolder">
            €{{ generalReportData.paymentReport.paymentVolume }}
          </h2>
          <span><b>{{ generalReportData.paymentReport.paymentCount }}</b> Payments</span>
        </div>
        <b-avatar
          :variant="`light-success`"
          size="45"
        >
          <feather-icon
            size="21"
            :icon="'DollarSignIcon'"
          />
        </b-avatar>
      </b-card-body>
    </b-card>
    <b-card no-body class="customer-rep-card">
      <b-card-body class="d-flex justify-content-between align-items-center">
        <div class="truncate">
          <h2 class="mb-25 font-weight-bolder">
            {{ generalReportData.customerReport.totalCustomer }}
          </h2>
          <span>New customer</span>
        </div>
        <b-avatar
          :variant="`light-success`"
          size="45"
        >
          <feather-icon
            size="21"
            :icon="'UsersIcon'"
          />
        </b-avatar>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
export default {
  computed: {
    generalReportData() {
      return this.$store.getters['homeReportManagement/getGeneralHomeReport'];
    },
  },
};
</script>

<style lang="css" scoped>
.payment-rep-card {
  margin-bottom: 0.5rem !important;
}

.payment-rep-card .card-body {
  padding: 1.2rem !important;
}

.customer-rep-card {
  margin-top: 0.5rem !important;
}
.customer-rep-card .card-body {
  padding: 1.2rem !important;
}
</style>
