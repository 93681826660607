<template>
  <div>
    <b-row>
      <b-col xl="12" md="12">
        <b-dropdown
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          split
          :text="$store.state.homeReportManagement.generalReportType.text"
          right
          variant="primary"
          class="mb-1 float-right"
        >
          <b-dropdown-item
            v-for="option in reportTypeOptions"
            :key="option.value"
            @click="onChangeReportType(option)"
          >
            {{ option.text }}
          </b-dropdown-item>
        </b-dropdown>
        <spinner-loader />
        <feather-icon
          icon="RefreshCwIcon"
          size="21"
          class="cursor-pointer"
          @click="onFetchGeneralReport()"
          v-if="!$store.getters.getLoader"
        />
      </b-col>
      <b-col xl="8" md="8">
        <home-general-value-report />
      </b-col>
      <b-col xl="4" md="4">
        <home-general-p-and-c-report />
      </b-col>
    </b-row>
    <open-ticket-list />
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import HomeGeneralValueReport from './home-reports/HomeGeneralValueReport.vue'
import HomeGeneralPAndCReport from './home-reports/HomeGeneralPAndCReport.vue'
import OpenTicketList from './others/ticket-manage/OpenTicketList.vue'

export default {
  directives: {
    Ripple,
  },
  components: {
    HomeGeneralValueReport,
    HomeGeneralPAndCReport,
    OpenTicketList,
  },
  data() {
    return {
      reportTypeOptions: [
        { text: 'Daily', value: 0 },
        { text: 'Weekly', value: 1 },
        { text: 'Monthly', value: 2 },
        { text: 'Yearly', value: 3 },
      ],
    };
  },
  methods: {
    onFetchGeneralReport() {
      this.$store.dispatch('homeReportManagement/fetchgGeneralHomeReport', { homeReportType: this.$store.state.homeReportManagement.generalReportType.value });
    },
    onChangeReportType(option) {
      this.$store.state.homeReportManagement.generalReportType = option;
      this.onFetchGeneralReport();
    },
  },
  created() {
    this.onFetchGeneralReport();
  },
}
</script>
